import { ImportConfiguration } from "../../types/importConfiguration";
import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getTemplate = (param?: string) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.FILE.DOWNLOAD_TEMPLATE(param)}`,
    responseType: "blob",
  });
};

export const uploadAndGetWorksheets = (data: any) => {
  return request({
    method: "POST",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.FILE.UPLOAD_AND_GET_WORKSHEETS}`,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getImportConfigurations = (
  organizationId: number,
  tenantId: number,
  adapterIdentifier: string
) => {
  return request({
    method: "GET",
    // eslint-disable-next-line max-len
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.IMPORT.GET_IMPORT_CONFIGURATIONS(organizationId, tenantId, adapterIdentifier)}`,
  });
};

export const getDefaultImportConfiguration = (
  organizationId: number,
  tenantId: number,
  adapterIdentifier: string,
  destinationIdentifier: string
) => {
  return request({
    method: "GET",
    // eslint-disable-next-line max-len
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.IMPORT.GET_DEFAULT_IMPORT_CONFIGURATION(organizationId, tenantId, adapterIdentifier, destinationIdentifier)}`,
  });
};

export const getDestinationMetadata = (
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.IMPORT.GET_DESTINATION_METADATA()}`,
  });
};

export const getSourceProperties = (organizationId: number,
  tenantId: number,
  adapterIdentifier: string,
  sourceIdentifier: string,
  importConfigurationArguments: Record<string, string>) => {
  return request({
    method: "POST",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.IMPORT.GET_SOURCE_PROPERTIES()}`,
    data: {
      organizationId,
      tenantId,
      sourceIdentifier,
      adapterIdentifier,
      importConfigurationArguments
    },
    headers: {
      "Content-Type": "application/json"
    },
  });
};

export const saveImportConfiguration = (organizationId: number,
  importConfiguration: ImportConfiguration) => {
  return request({
    method: "POST",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.IMPORT.SAVE_IMPORT_CONFIGURATION()}`,
    data: {
      organizationId,
      importConfiguration
    },
    headers: {
      "Content-Type": "application/json"
    },
  });
};

export const getPreview = (organizationId: number,
  tenantId: number,
  adapterIdentifier: string,
  sourceIdentifier: string,
  destinationIdentifier: string,
  importConfigurationArguments: Record<string, string>,
  destinationSourceFieldMappings: Record<string, string>) => {
  return request({
    method: "POST",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.IMPORT.GET_PREVIEW()}`,
    data: {
      organizationId,
      tenantId,
      sourceIdentifier,
      destinationIdentifier,
      adapterIdentifier,
      importConfigurationArguments,
      destinationSourceFieldMappings
    },
    headers: {
      "Content-Type": "application/json"
    },
  });
};

export const saveData = (organizationId: number,
  tenantId: number,
  adapterIdentifier: string,
  sourceIdentifier: string,
  destinationIdentifier: string,
  importConfigurationArguments: Record<string, string>
) => {
  return request({
    method: "POST",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.IMPORT.SAVE_DATA()}`,
    data: {
      organizationId,
      tenantId,
      sourceIdentifier,
      destinationIdentifier,
      adapterIdentifier,
      importConfigurationArguments
    },
    headers: {
      "Content-Type": "application/json"
    }
  });
};