import styles from "./TemplateChooser.module.scss";

import { Description } from "../../../Description";
import { Template } from "../../../Template";
import { Title } from "../../../Title";

export const TemplateChooser = () => {
  return (
    <div className={styles.root}>
      <div>
        <Title tagName={"h3"}>Templates</Title>
        <Description theme={"t2"} className={styles.description}>
          Select and download a template
        </Description>
      </div>
      <div className={styles.wrapper}>
        <Template
          typeTemplate={"ProjectSummary"}
          titleTemplate={"Project Summary Template.xlsx"}
          textDropdown={
            "Use this template to list your projects names, start, finish dates, statuses, progress."
          }
          className={styles.template}
        />
        <Template
          typeTemplate={"MilestoneSummary"}
          titleTemplate={"Milestones Summary Template.xlsx"}
          textDropdown={
            "List your tasks, task types, dates, responsible resources and reference to projects. "
          }
          className={styles.template}
        />
        <Template
          typeTemplate={"IssueSummary"}
          titleTemplate={"Issues Summary Template.xlsx"}
          textDropdown={
            "List project issues, descriptions, due dates, urgency to resolve, statuses, etc."
          }
          className={styles.template}
        />
        <Template
          titleTemplate={"DEMO Data Project Summary Template.xlsx"}
          textDropdown={"Login to download Demo template to your computer"}
          className={styles.lastTemplate}
          showHelp={false}
        />
      </div>
    </div>
  );
};
