import { useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { PreviewTemplate } from "./components/PreviewTemplate";
import styles from "./PreviewPage.module.scss";

import { saveData } from "../../api/template/template";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrowBack-icon.svg";
import demoPreview from "../../assets/img/DEMO_Project_Summary.jpg";
import { PATHS } from "../../constants/PATHS";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useCurrentInfoFiles } from "../../hooks/useCurrentInfoFiles";
import { useUserData } from "../../hooks/useUserProfile";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import {
  selectSliceSelectedImportConfiguration
} from "../../store/slices/importConfigurationSlice";
import { selectParseResult } from "../../store/slices/parseResultSlice";
import {
  setTemplateSPage,
  setTemplateStep,
} from "../../store/slices/stepSlice";
import { selectDemo, setDemo } from "../../store/slices/uploadFileSlice";
import { ImportConfiguration } from "../../types/importConfiguration";
import { ETemplatePage, ETemplateStep } from "../../types/steps";
import { createErrorMessage } from "../../utils/createErrorMessage";
import { Auth } from "../Auth";
import { CustomButton } from "../FormFields/CustomButton";
import { Loader } from "../Loader";
import { ReactPortal } from "../ReactPortal";
import { ReactPortalSubstrate } from "../ReactPortalSubstrate";
import { Title } from "../Title";

export const PreviewPage = () => {
  const initialSelectedImportConfiguration = useAppSelector(selectSliceSelectedImportConfiguration);
  const mapImportConfigurationArguments = (importConfiguration: ImportConfiguration): Record<string, string> => {
    const importConfigurationArguments = importConfiguration.importConfigurationArguments
      .reduce<Record<string, string>>((dict, item) => {
        dict[item.identifier] = item.value;
        return dict;
      }, {});
    importConfigurationArguments["FileId"] = parseResult.fileId;
    return importConfigurationArguments;
  };

  const [isPortalOpen, seIsPortalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [token] = useCookies(["accessToken"]);

  const parseResult = useAppSelector(selectParseResult);
  const isDemo = useAppSelector(selectDemo);

  const { organizationId, tenantId } = useUserData();
  const { refetch } = useCurrentInfoFiles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formRef = useRef<HTMLDivElement>(null);

  const onBackClick = () => {
    if (isDemo === "demo") {
      dispatch(setTemplateSPage(ETemplatePage.SelectTemplatePage));
      dispatch(setTemplateStep(ETemplateStep.step2));
      dispatch(setDemo(null));
    }
    else {
      dispatch(setTemplateSPage(ETemplatePage.ImportConfigurationFieldMappingPage));
      dispatch(setTemplateStep(ETemplateStep.step4));
      dispatch(setDemo(null));
    }
  };

  const onSaveClick = async () => {
    setIsLoading(true);
    
    if (!initialSelectedImportConfiguration) {
      throw new Error('initialSelectedImportConfiguration is null');
    }

    if (!initialSelectedImportConfiguration.sourceIdentifier) {
      throw new Error("sourceIdentifier is null!");
    }
    const importConfigurationArguments = mapImportConfigurationArguments(initialSelectedImportConfiguration);

    try {
      const res = await saveData(
        organizationId,
        tenantId,
        initialSelectedImportConfiguration.adapterIdentifier,
        initialSelectedImportConfiguration.sourceIdentifier,
        initialSelectedImportConfiguration.destinationIdentifier,
        importConfigurationArguments);

      if (token.accessToken) {
        await refetch();

        navigate(PATHS.REPORTS_PATH);
      }

      dispatch(setTemplateStep(ETemplateStep.step1));
      dispatch(setTemplateSPage(ETemplatePage.ConnectPage));
    } catch (error: any) {
      toast.error(createErrorMessage(error));
    }
  };

  const onOpenClick = () => {
    seIsPortalOpen(true);
  };

  const onCloseClick = () => {
    seIsPortalOpen(false);
  };

  useClickOutside(formRef, onCloseClick);

  return (
    <div className={styles.root}>
      {token.accessToken && isDemo !== "demo" && (
        <Title tagName={"h3"} className={styles.title}>
          Preview and save imported data
        </Title>
      )}
      {isDemo === "demo" && (
        <img
          src={demoPreview}
          alt="template"
          loading="lazy"
          className={styles.image}
        />
      )}
      {isDemo !== "demo" && <PreviewTemplate />}

      <div className={styles.wrapperBtn}>
        <CustomButton
          theme={"secondary"}
          className={styles.btn}
          onClick={onBackClick}
        >
          <ArrowBack className={styles.icon} />
          Back
        </CustomButton>
        {isDemo !== "demo" && token.accessToken && (
          <CustomButton
            className={styles.btn}
            onClick={onSaveClick}
            isDisabled={isLoading}
          >
            {isLoading && <Loader />}
            {!isLoading && "Save"}
          </CustomButton>
        )}
      </div>

      {!token.accessToken && (
        <ReactPortal>
          <div className={styles.footerLogin}>
            <CustomButton className={styles.loginBtn} onClick={onOpenClick}>
              Login to continue
            </CustomButton>
          </div>
        </ReactPortal>
      )}
      {isPortalOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <Auth ref={formRef} onClose={onCloseClick} />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </div>
  );
};
