import { useQuery } from "@tanstack/react-query";
import { type TableProps } from 'antd';
import { ItemType } from "antd/es/menu/interface";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import styles from "./ImportConfigurationPage.module.scss";

// eslint-disable-next-line max-len
import { getDefaultImportConfiguration, getDestinationMetadata, getImportConfigurations } from "../../api/template/template";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrowBack-icon.svg";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useUserData } from "../../hooks/useUserProfile";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import {
  selectSliceImportConfigurations,
  selectSliceSelectedImportConfiguration,
  setSliceImportConfigurations,
  setSliceSelectedImportConfiguration
} from "../../store/slices/importConfigurationSlice";
import { selectParseResult } from "../../store/slices/parseResultSlice";
import {
  setTemplateSPage,
  setTemplateStep,
} from "../../store/slices/stepSlice";
import { selectDemo, setDemo } from "../../store/slices/uploadFileSlice";
import { EntityMetadata } from "../../types/entityMetadata";
import { ImportConfiguration, ImportConfigurationArgument } from "../../types/importConfiguration";
import { ETemplatePage, ETemplateStep } from "../../types/steps";
import { createErrorMessage } from "../../utils/createErrorMessage";
import { Auth } from "../Auth";
import { CustomButton } from "../FormFields/CustomButton";
import { CustomColumnsType, CustomTable, SelectOption } from '../FormFields/CustomTable';
import { ReactPortal } from "../ReactPortal";
import { ReactPortalSubstrate } from "../ReactPortalSubstrate";
import { Title } from "../Title";

export const ImportConfigurationPage = () => {
  const adapterIdentifier = "ExcelAdapter";
  const { organizationId, tenantId } = useUserData();

  const [destinationButtonDropdownItems, setDestinationButtonDropdownItems] = useState<ItemType[]>([]);
  const [importConfigurations, setImportConfigurations] = useState<ImportConfiguration[]>([]);
  const [selectedImportConfiguration, setSelectedImportConfiguration] = useState<ImportConfiguration | null>(null);
  const [selectedImportConfigurationKeys, setSelectedImportConfigurationKeys] = useState<React.Key[]>([]);

  const mapToSelectOptions = (metadata: EntityMetadata[]): SelectOption[] => {
    return metadata.map(item => ({
      label: item.displayName,
      value: item.identifier,
    }));
  };

  const mapToButtonDropdownItems = (metadata: EntityMetadata[]): ItemType[] => {
    return metadata.map(item => ({
      label: item.displayName,
      key: item.identifier,
    }));
  };

  const parseResult = useAppSelector(selectParseResult);
  const sourceTables = mapToSelectOptions(parseResult.entityMetadata);

  const importConfigurationColumns: CustomColumnsType<ImportConfiguration> = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a, b) => {
        return a.id - b.id
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      filterSearch: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      editable: true
    },
    {
      title: 'Source',
      dataIndex: 'sourceIdentifier',
      editable: true,
      inputType: 'dropdown',
      options: sourceTables
    },
    {
      title: 'Destination',
      dataIndex: 'destinationIdentifier'
    },
  ];

  const importConfigurationSelection: TableProps<ImportConfiguration>['rowSelection'] = {
    type: 'radio',
    selectedRowKeys: selectedImportConfigurationKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: ImportConfiguration[]) => {
      if (selectedRows && selectedRows.length === 1) {
        setSelectedImportConfiguration(selectedRows[0]);
        setSelectedImportConfigurationKeys(selectedRowKeys);
      }
    },
  };

  const importConfigurationArgumentColumns: CustomColumnsType<ImportConfigurationArgument> = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a, b) => {
        return a.id - b.id
      }
    },
    {
      title: 'DisplayName',
      dataIndex: 'displayName'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      editable: true
    }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const isDemo = useAppSelector(selectDemo);

  const initialImportConfigurations = useAppSelector(selectSliceImportConfigurations) ?? [];
  const initialSelectedImportConfiguration = useAppSelector(selectSliceSelectedImportConfiguration);

  useEffect(() => {
    if (initialImportConfigurations.length > 0 && importConfigurations.length === 0) {

      const tempConfigs = initialImportConfigurations.map(item => ({ ...item }));

      tempConfigs.forEach((importConfiguration: ImportConfiguration) => {
        const isAvailable = sourceTables.some(
          (available) => available.value === importConfiguration.sourceIdentifier
        );

        if (!isAvailable) {
          importConfiguration.sourceIdentifier = null;
        }
      });
      setImportConfigurations(tempConfigs);
    }
  }, [initialImportConfigurations, importConfigurations.length]);

  useEffect(() => {
    if (initialSelectedImportConfiguration && !selectedImportConfiguration) {
      setSelectedImportConfiguration(initialSelectedImportConfiguration);
      setSelectedImportConfigurationKeys([initialSelectedImportConfiguration.id]);
    }
  }, [initialSelectedImportConfiguration, selectedImportConfiguration, selectedImportConfigurationKeys]);

  const { data: importConfigurationsData } = useQuery({
    queryKey: ["getImportConfigurations", tenantId, organizationId, adapterIdentifier],
    queryFn: async () => {
      try {
        const res = await getImportConfigurations(organizationId,
          tenantId,
          adapterIdentifier);

        res.data.data.forEach((importConfiguration: ImportConfiguration) => {
          const isAvailable = sourceTables.some(
            (available) => available.value === importConfiguration.sourceIdentifier
          );

          if (!isAvailable) {
            importConfiguration.sourceIdentifier = null;
          }
        });

        setImportConfigurations(res.data.data);
        // setIsLoading(false);
        // setTableParams({
        //   ...tableParams,
        //   pagination: {
        //     ...tableParams.pagination,
        //     total: importConfigurations.length,
        //     // total: data.totalCount,
        //   },
        // });
        return res.data.data;
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
    enabled: !!tenantId
  });

  const { data: destinationMetadataData } = useQuery({
    queryKey: ["getDestinationMetadata"],
    queryFn: async () => {
      try {
        const res = await getDestinationMetadata();
        return res.data.data;
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
    enabled: !!tenantId
  });


  useEffect(() => {
    if (destinationMetadataData && destinationMetadataData.length > 0) {
      setDestinationButtonDropdownItems(mapToButtonDropdownItems(destinationMetadataData));
      // setDestinationMetadata(destinationMetadataData);
      // setSelectedDestinationidentifier(destinationMetadataData[0].identifier);
      // const destinationButtonDropdownItems = mapToButtonDropdownItems(destinationMetadataData);
    }
  }, [destinationMetadataData]);

  const [isPortalOpen, seIsPortalOpen] = useState(false);
  const [token] = useCookies(["accessToken"]);

  // const { organizationId, tenantId } = useUserData();
  // const { refetch } = useCurrentInfoFiles();

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  const formRef = useRef<HTMLDivElement>(null);

  const onBackClick = () => {
    dispatch(setSliceImportConfigurations(importConfigurations));
    dispatch(setSliceSelectedImportConfiguration(selectedImportConfiguration));

    dispatch(setTemplateSPage(ETemplatePage.SelectTemplatePage));
    dispatch(setTemplateStep(ETemplateStep.step2));
    dispatch(setDemo(null));
  };

  const onNextClick = () => {
    dispatch(setSliceImportConfigurations(importConfigurations));
    dispatch(setSliceSelectedImportConfiguration(selectedImportConfiguration));

    dispatch(setTemplateSPage(ETemplatePage.ImportConfigurationFieldMappingPage));
    dispatch(setTemplateStep(ETemplateStep.step4));
  };

  // public int Id { get; } = id;
  // public string Name { get; } = name;
  // public int TenantId { get; } = tenantId;

  // /// <summary>
  // /// Adapter to translate data from Source (Excel, Dataverse, etc) to intermediate common structure
  // /// </summary>
  // public string AdapterIdentifier { get; } = adapterIdentifier;
  // /// <summary>
  // /// Destination entity identifier
  // /// </summary>
  // public string EntityIdentifier { get; } = entityIdentifier;
  // public IReadOnlyCollection<ImportConfigurationArgumentModel> 
  //ImportConfigurationArguments { get; } = importConfigurationArguments;
  // public IReadOnlyCollection<ImportConfigurationFieldMappingModel> 
  //ImportConfigurationFieldMappings { get; } = importConfigurationFieldMappings;
  const isDisable = () => {
    if (!selectedImportConfiguration || !selectedImportConfiguration.sourceIdentifier) {
      return true;
    }

    return false;
  };

  // const onSaveClick = async () => {
  //   setIsLoading(true);

  //   try {
  //     const res = await saveTemplate(template.fileId, organizationId, tenantId);

  //     if (token.accessToken) {
  //       await refetch();

  //       navigate(PATHS.REPORTS_PATH);
  //     }

  //     dispatch(setTemplateStep(ETemplateStep.step1));
  //     dispatch(setTemplateSPage(ETemplatePage.ConnectPage));
  //   } catch (error: any) {
  //     toast.error(createErrorMessage(error));
  //   }
  // };

  const onOpenClick = () => {
    seIsPortalOpen(true);
  };

  const onCloseClick = () => {
    seIsPortalOpen(false);
  };

  useClickOutside(formRef, onCloseClick);

  const handleDataSourceImportConfigurationChange = (newDataSource: ImportConfiguration[]) => {
    setImportConfigurations(newDataSource);

    const last = newDataSource[newDataSource.length - 1];

    setSelectedImportConfiguration(last);
    setSelectedImportConfigurationKeys([last.id]);
  };

  return (
    <div className={styles.root}>
      {token.accessToken && isDemo !== "demo" && (
        <Title tagName={"h3"} className={styles.title}>
          Setup import configurtaion
        </Title>
      )}
      {/* {isDemo === "demo" && (
        <img
          src={demoPreview}
          alt="template"
          loading="lazy"
          className={styles.image}
        />
      )} */}
      {/* {isDemo !== "demo" && <PreviewTemplate />} */}

      {/* items,
      idKey,
      fieldConfigs,
      onItemsChange,
      onSelect, */}

      {/* <div style={{ width: '100%' }}>
        <Select
          value={selectedDestinationidentifier}
          onChange={onDestinationMetadataDataChange}
          style={{ width: '100%' }}
        >
          {destinationOptions?.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div> */}
      <div>
        {/* <CustomTable {...customTableConfig}/> */}
        {/* <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          Add
        </Button> */}
        {/* <Table<ImportConfiguration>
          dataSource={importConfigurations}
          columns={columns}
          rowKey={(record) => record.id}
          loading={isLoading}
          rowSelection={rowSelection}
        /> */}
        <CustomTable<ImportConfiguration>
          dataSource={importConfigurations}
          columns={importConfigurationColumns}
          getRowKey={(record) => record.id}
          loading={isLoading}
          rowSelection={importConfigurationSelection}
          allowAddDelete={true}
          createItemFactory={async (destinationIdentifier) => {
            if (!destinationIdentifier) {
              throw new Error('destinationIdentifier is null');
            }
            const res = await getDefaultImportConfiguration(organizationId,
              tenantId,
              adapterIdentifier,
              destinationIdentifier);
            const defaultImportConfiguration = res.data.data;
            const newRecord: ImportConfiguration = {
              id: defaultImportConfiguration.id,
              tenantId: tenantId,
              adapterIdentifier: adapterIdentifier,
              destinationIdentifier: destinationIdentifier,
              sourceIdentifier: '',
              name: defaultImportConfiguration.name,
              importConfigurationArguments: defaultImportConfiguration.importConfigurationArguments,
              importConfigurationFieldMappings: defaultImportConfiguration.importConfigurationFieldMappings
            }
            return newRecord;
          }}
          onDataSourceChange={handleDataSourceImportConfigurationChange}
          addButtonItems={destinationButtonDropdownItems}
        />
        <CustomTable<ImportConfigurationArgument>
          dataSource={selectedImportConfiguration?.importConfigurationArguments ?? []}
          columns={importConfigurationArgumentColumns}
          getRowKey={(record) => record.id}
          loading={isLoading}
          allowAddDelete={false}
        />
      </div>

      {/* <div className={styles.wrapperSelect}>
        <CustomSelect
          label="Report’s Title"
          placeholder="Select your role"
          selectTheme={"withoutBorder"}
          isSearchable={false}
          value={
            formik.values.reportTitle
              ? {
                value: formik.values.reportTitle,
                label: formik.values.reportTitle,
                id: "",
              }
              : undefined
          }
          options={[
            {
              value: "All my reports",
              label: "All my reports",
              id: "",
            },
            {
              value: "Project Summary",
              label: "Project Summary",
              id: "ProjectSummary",
            },
            {
              value: "Milestones Summary",
              label: "Milestones Summary",
              id: "MilestoneSummary",
            },
            {
              value: "Issues Summary",
              label: "Issues Summary",
              id: "IssueSummary",
            },
          ]}
        />
      </div> */}
      <div className={styles.wrapperBtn}>
        <CustomButton
          theme={"secondary"}
          className={styles.btn}
          onClick={onBackClick}
        >
          <ArrowBack className={styles.icon} />
          Back
        </CustomButton>
        <CustomButton
          isDisabled={isDisable()}
          className={styles.btn}
          onClick={onNextClick}
        >
          Next
        </CustomButton>
      </div>

      {!token.accessToken && (
        <ReactPortal>
          <div className={styles.footerLogin}>
            <CustomButton className={styles.loginBtn} onClick={onOpenClick}>
              Login to continue
            </CustomButton>
          </div>
        </ReactPortal>
      )}
      {isPortalOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <Auth ref={formRef} onClose={onCloseClick} />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </div>
  );
};