/* eslint-disable */
import { useQuery } from "@tanstack/react-query";
import { type TableProps } from 'antd';
import { ItemType } from "antd/es/menu/interface";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import styles from "./ImportConfigurationFieldMappingPage.module.scss";

// eslint-disable-next-line max-len
import { getDefaultImportConfiguration, getDestinationMetadata, getImportConfigurations, getPreview, getSourceProperties, saveImportConfiguration } from "../../api/template/template";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrowBack-icon.svg";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useUserData } from "../../hooks/useUserProfile";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import {
  selectSliceImportConfigurations,
  selectSliceSelectedImportConfiguration,
  setSliceImportConfigurations,
  setSliceSelectedImportConfiguration
} from "../../store/slices/importConfigurationSlice";
import {
  setTemplateSPage,
  setTemplateStep,
} from "../../store/slices/stepSlice";
import { selectParseResult } from "../../store/slices/parseResultSlice";
import { selectDemo, setDemo } from "../../store/slices/uploadFileSlice";
import { ImportConfiguration, ImportConfigurationArgument, ImportConfigurationFieldMapping } from "../../types/importConfiguration";
import { ETemplatePage, ETemplateStep } from "../../types/steps";
import { createErrorMessage } from "../../utils/createErrorMessage";
import { Auth } from "../Auth";
import { CustomButton } from "../FormFields/CustomButton";
import { CustomColumnsType, CustomTable, SelectOption } from '../FormFields/CustomTable';
import { ReactPortal } from "../ReactPortal";
import { ReactPortalSubstrate } from "../ReactPortalSubstrate";
import { Title } from "../Title";
import { PropertyMetadata } from "../../types/entityMetadata";
import { setSlicePreview } from "../../store/slices/previewSlice";

export const ImportConfigurationFieldMappingPage = () => {
  const { organizationId, tenantId } = useUserData();
  const [sourceProperties, setSourceProperties] = useState<SelectOption[]>([]);

  const mapToSelectOptions = (metadata: PropertyMetadata[]): SelectOption[] => {
    return metadata.map(item => ({
      label: item.displayName,
      value: item.identifier,
    }));
  };

  const mapImportConfigurationArguments = (importConfiguration: ImportConfiguration): Record<string, string> => {
    const importConfigurationArguments = importConfiguration.importConfigurationArguments
      .reduce<Record<string, string>>((dict, item) => {
        dict[item.identifier] = item.value;
        return dict;
      }, {});
    importConfigurationArguments["FileId"] = parseResult.fileId;
    return importConfigurationArguments;
  };

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const initialImportConfigurations = useAppSelector(selectSliceImportConfigurations);
  const initialSelectedImportConfiguration = useAppSelector(selectSliceSelectedImportConfiguration);
  if (!initialSelectedImportConfiguration) {
    throw new Error('initialSelectedImportConfiguration is null');
  }

  const tempMappings = initialSelectedImportConfiguration.importConfigurationFieldMappings
    .map(item => ({ ...item }));

  const [importConfigurationFieldMappings, setImportConfigurationFieldMappings] = useState<ImportConfigurationFieldMapping[]>(tempMappings);

  const parseResult = useAppSelector(selectParseResult);

  const importConfigurationArguments = mapImportConfigurationArguments(initialSelectedImportConfiguration);

  const { data: sourcePropertiesData } = useQuery({
    queryKey: ["getSourceProperties", tenantId,
      organizationId,
      initialSelectedImportConfiguration?.adapterIdentifier,
      importConfigurationArguments],
    queryFn: async () => {
      try {
        if (!initialSelectedImportConfiguration.sourceIdentifier) {
          throw new Error("sourceIdentifier is null!");
        }
        const res = await getSourceProperties(
          organizationId,
          tenantId,
          initialSelectedImportConfiguration.adapterIdentifier,
          initialSelectedImportConfiguration.sourceIdentifier,
          importConfigurationArguments
        );
        return res.data.data;
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
    enabled: !!tenantId
  });

  useEffect(() => {
    if (sourcePropertiesData && sourcePropertiesData.length > 0) {

      const availableSourceIdentifiers = sourcePropertiesData as PropertyMetadata[];

      importConfigurationFieldMappings.forEach((mapping) => {
        const isAvailable = availableSourceIdentifiers.some(
          (available) => available.identifier === mapping.sourceFieldIdentifier
        );

        if (!isAvailable) {
          mapping.sourceFieldIdentifier = null;
        }
      });

      setSourceProperties(mapToSelectOptions(sourcePropertiesData));
    }
  }, [sourcePropertiesData]);

  const importConfigurationFieldMappingColumns: CustomColumnsType<ImportConfigurationFieldMapping> = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a, b) => {
        return a.id - b.id
      }
    },
    {
      title: 'Source Field Identifier',
      dataIndex: 'sourceFieldIdentifier',
      editable: true,
      inputType: 'dropdown',
      options: sourceProperties
    },
    {
      title: 'Destination Field Identifier',
      dataIndex: 'destinationFieldIdentifier'
    }
  ];

  const isDisable = () => {
    const valid = importConfigurationFieldMappings.every(item => item.sourceFieldIdentifier !== null);
    return !valid;
  };

  const onBackClick = () => {
    dispatch(setTemplateSPage(ETemplatePage.ImportConfigurationPage));
    dispatch(setTemplateStep(ETemplateStep.step3));
    dispatch(setDemo(null));
  };

  const onNextClick = async () => {
    const importConfigurationArguments = mapImportConfigurationArguments(initialSelectedImportConfiguration);

    const destinationSourceFieldMappings = initialSelectedImportConfiguration.importConfigurationFieldMappings
      .reduce<Record<string, string>>((dict, item) => {
        if (!item.sourceFieldIdentifier) {
          throw new Error('sourceFieldIdentifier is null');
        }
        dict[item.destinationFieldIdentifier] = item.sourceFieldIdentifier;
        return dict;
      }, {});

    if (!initialSelectedImportConfiguration.sourceIdentifier) {
      throw new Error("sourceIdentifier is null!");
    }
    const res = await getPreview(
      organizationId,
      tenantId,
      initialSelectedImportConfiguration.adapterIdentifier,
      initialSelectedImportConfiguration.sourceIdentifier,
      initialSelectedImportConfiguration.destinationIdentifier,
      importConfigurationArguments,
      destinationSourceFieldMappings
    );

    dispatch(setSlicePreview(res.data.data))

    dispatch(setTemplateSPage(ETemplatePage.PreviewPage));
    dispatch(setTemplateStep(ETemplateStep.step5));
  };

  const onSaveConfiguration = async () => {
    try {
      const config = { ...initialSelectedImportConfiguration };
      config.importConfigurationFieldMappings = importConfigurationFieldMappings;
      const res = await saveImportConfiguration(organizationId, config);

      dispatch(setSliceSelectedImportConfiguration(res.data.data));
      const tempMappings = res.data.data.importConfigurationFieldMappings
        .map((item: ImportConfigurationFieldMapping) => {
          return { ...item };
        });
      setImportConfigurationFieldMappings(tempMappings);

      if (!initialImportConfigurations) {
        throw new Error('initialImportConfigurations is null');
      }
      const updatedImportConfigurations = initialImportConfigurations.map(obj =>
        obj.id === config.id ? res.data.data : obj
      );
      dispatch(setSliceImportConfigurations(updatedImportConfigurations));
    } catch (error: any) {
      toast.error(createErrorMessage(error));
    }
  };

  return (
    <div className={styles.root}>
      <div>
        {initialSelectedImportConfiguration?.name}
      </div>
      <div>
        {initialSelectedImportConfiguration?.sourceIdentifier}
      </div>
      <div>
        {initialSelectedImportConfiguration?.destinationIdentifier}
      </div>
      <div style={{
        maxHeight: '50vh', // Maximum height for the container
        overflow: 'auto',   // Enable scrolling if content overflows
        border: '1px solid #d9d9d9', // Optional styling
        padding: '8px', // Optional padding
      }}>
        <CustomTable<ImportConfigurationFieldMapping>
          dataSource={importConfigurationFieldMappings}
          columns={importConfigurationFieldMappingColumns}
          getRowKey={(record) => record.id}
          loading={isLoading}
          allowAddDelete={false}
          onDataSourceChange={setImportConfigurationFieldMappings}
        />
      </div>

      <div className={styles.wrapperBtn}>
        <CustomButton
          theme={"secondary"}
          className={styles.btn}
          onClick={onBackClick}
        >
          <ArrowBack className={styles.icon} />
          Back
        </CustomButton>
        <CustomButton
          isDisabled={isDisable()}
          className={styles.btn}
          onClick={onSaveConfiguration}
        >
          Save Configuration
        </CustomButton>
        <CustomButton
          isDisabled={isDisable()}
          className={styles.btn}
          onClick={onNextClick}
        >
          Next
        </CustomButton>
      </div>
    </div>
  );
};